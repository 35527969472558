import React from 'react'
import Layout from '../components/layout'
import KeyVisual from '../components/modules/visuals/TheKeyVisual'
import Interviews from '../components/organisms/Interviews'
import SEO from '../components/seo'

import image from '../images/interviews.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Interviews" />
    <KeyVisual title="Interviews" image={image} />
    <Interviews />
  </Layout>
)

export default IndexPage
